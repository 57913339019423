import { useEffect } from "react";
import { useState } from "react";
import { Loader, Status } from "../components/Loader";
import { confetti } from "../components/confetti";

export const StatusPage = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");

  const setResponse = (status, info) => {
    setStatus(status);
    setInfo(info);
    setLoading(false);
  };

  useEffect(() => {
    if (!status) {
      return;
    }
    if (status !== "SUCCESS") {
      return;
    }
    confetti();
  }, [status]);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    if (!params) {
      setResponse("NOT FOUND", "No details found.");
      return;
    }
    const status = params.get("status");
    const info = params.get("info");
    if (!status || !info) {
      setResponse("NOT FOUND", "The status of your payment was not found.");
      return;
    }
    console.log(info);
    setResponse(status, info);
    setLoading(false);
    return;
  }, []);

  return loading ? <Loader /> : <Status status={status} info={info} />;
};
