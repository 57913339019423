import { showToast } from "../../../utils/toastModal";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";

export const getRebrandlyLinks = async (uid) => {
  const rebrandlyDocRef = doc(db, "rebrandly", uid);

  const rebrandlyDoc = await getDoc(rebrandlyDocRef);

  if (rebrandlyDoc.exists()) {
    return rebrandlyDoc.data().rebrandly;
  } else {
    showToast(false, "No Dynamic Links found, contact support to get started.");
    return;
  }
};
