import {
  doc,
  getDoc,
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

export const getMerchantDocumentByUid = async (uid) => {
  const docRef = doc(db, "merchants", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
  }
};

// onSnapshot function that returns all of the merchants customers in an array of objects

export async function getCustomers(
  uid,
  setCustomers,
  setIsLoadingCustomers,
  setVerifiedCustomers
) {
  const customersQuery = query(
    collection(db, "psu"),
    where("uid", "==", uid),
    orderBy("wholesaleAccount", "asc")
  );

  const unsubscribe = onSnapshot(customersQuery, async (snapshot) => {
    let customers = [];
    let verified = [];
    for (const documentSnapshot of snapshot.docs) {
      const customer = documentSnapshot.data();
      const id = documentSnapshot.id;
      await customers.push({ ...customer, id });
      if (customer.verified) verified.push({ ...customer, id });
    }
    setCustomers(customers);
    setIsLoadingCustomers(false);
    setVerifiedCustomers(verified);
  });

  return unsubscribe;
}

// Get a list of the employees from the merchant doc subcollection (dashboard routes)

export async function getMerchantEmployees(uid) {
  const ref = await getDocs(collection(db, `merchants/${uid}/employees`));

  if (ref.empty) {
    return [];
  }

  let employees = [];

  ref.forEach((employee) => {
    employees.push({
      ...employee.data(),
      id: employee.id,
      value: employee.data().initials,
      label: employee.data().name,
    });
  });

  return employees;
}

// Get the merchants details (dashboard routes)

export async function getMerchantDetails(uid) {
  const docRef = doc(db, "merchants", uid);

  const merchantDetails = await getDoc(docRef);

  if (!merchantDetails.exists()) {
    return null;
  }

  return { ...merchantDetails.data(), id: merchantDetails.id };
}

// Last two days SUCCESS payments for the home screen

export async function getLastTwoDaysOfLinks(uid) {
  const x = 2;
  const date = new Date(Date.now() - x * 24 * 60 * 60 * 1000); // x days ago
  const q = query(
    collection(db, "links"),
    where("uid", "==", uid),
    where("timeStamp", ">", date),
    where("status", "==", "SUCCESS")
  );

  const links = await getDocs(q);

  const linkArr = [];

  links.forEach((link) => {
    linkArr.push({ ...link.data(), id: link.id });
  });

  return linkArr;
}

// Get all payment links

export const getPaymentLinks = async (uid, setPaymentLinks) => {
  const now = new Date();
  const last30Days = new Date(now.setDate(now.getDate() - 30));
  const linkquery = query(
    collection(db, "links"),
    where("uid", "==", uid),
    where("timeStamp", ">", last30Days),
    orderBy("timeStamp", "desc"),
    limit(500)
  );

  const unsubscribe = onSnapshot(linkquery, async (snapshot) => {
    let links = [];
    for (const documentSnapshot of snapshot.docs) {
      const date = documentSnapshot.data().timeStamp.toDate();
      const link = documentSnapshot.data();
      const id = documentSnapshot.id;
      await links.push({
        ...link,
        id,
        tableDate: date.toLocaleString(),
        formattedDate: date.toLocaleDateString("en-GB"),
      });
    }
    setPaymentLinks(links);
  });

  return unsubscribe;
};

export const getRefundLinks = async (uid, setRefundLinks) => {
  const now = new Date();
  const last30Days = new Date(now.setDate(now.getDate() - 30));
  const refundquery = query(
    collection(db, "refunds"),
    where("uid", "==", uid),
    where("timeStamp", ">", last30Days),
    orderBy("timeStamp", "desc"),
    limit(500)
  );

  const unsubscribe = onSnapshot(refundquery, async (snapshot) => {
    let links = [];
    for (const documentSnapshot of snapshot.docs) {
      const date = documentSnapshot.data().timeStamp.toDate();
      const link = documentSnapshot.data();
      const id = documentSnapshot.id;
      await links.push({
        ...link,
        id,
        tableDate: date.toLocaleString(),
        formattedDate: date.toLocaleDateString("en-GB"),
      });
    }
    setRefundLinks(links);
  });

  return unsubscribe;
};
