import { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { UserAuth } from "../../context/AuthContext";
import { FiMinus, FiPlusSquare } from "react-icons/fi";
import { Modal } from "../components/Modal";
import { NewRefund } from "./components/NewRefund";
import SearchBar from "../components/SearchBar";
import { FiCopy, FiTrash2 } from "react-icons/fi";
import { copyUrl } from "./actions/copyUrl";
import { getRefundLinks } from "../../firebase/firestore";
import { deleteDocument } from "../../firebase/crud";
import "../styles.css";

const options = [
  { value: "reference", label: "Reference" },
  { value: "wholesaleAccountNumber", label: "Account Number" },
  { value: "status", label: "Status" },
  { value: "timeStamp", label: "Date & Time" },
];

const RefundLinks = ({ verifiedCustomers }) => {
  const { user } = UserAuth();
  const [refundLinks, setRefundLinks] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [searchBy, setSearchBy] = useState("reference");
  const [sort, setSort] = useState("reference");
  const [sortDirection, setSortDirection] = useState(false);
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(25);
  const [showOnlySuccess, setShowOnlySuccess] = useState(false);

  useEffect(() => {
    if (user) {
      async function runGetRefunds() {
        const uid = user.uid;
        const unsubscribe = await getRefundLinks(uid, setRefundLinks);
        return () => unsubscribe();
      }
      runGetRefunds();
    }
  }, [user]);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const filteredItems = useMemo(() => {
    if (!refundLinks) return;
    return refundLinks.filter((item) => {
      return item[searchBy].toLowerCase().includes(query.toLowerCase());
    });
  }, [refundLinks, query, sort, sortDirection, searchBy]);

  const sortData = async (direction) => {
    setSortDirection(direction);
    let newList;
    if (sortDirection) {
      newList = refundLinks.sort((a, b) => {
        if (sort !== "timeStamp") {
          return a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1;
        } else {
          return a[sort] - b[sort];
        }
      });
    } else {
      newList = refundLinks.sort((a, b) => {
        if (sort !== "timeStamp") {
          return a[sort].toLowerCase() > b[sort].toLowerCase() ? -1 : 1;
        } else {
          return b[sort] - a[sort];
        }
      });
    }

    setRefundLinks(newList);
  };

  function onlyShowSuccess() {
    if (showOnlySuccess) {
      setQuery("");
      setSearchBy("status");
    } else {
      setQuery("SUCCESS");
      setSearchBy("status");
    }
    setShowOnlySuccess((prev) => !prev);
  }

  return (
    <>
      <div className="page-header">
        <h1 className="page-title">Refunds</h1>
        <div className="page-header-buttons">
          {verifiedCustomers.length === 0 ? (
            <></>
          ) : (
            <button
              onClick={() => setIsOpen(true)}
              className="button-37"
              role="button"
            >
              + Create New Refund
            </button>
          )}
        </div>
      </div>

      <SearchBar
        query={query}
        setQuery={setQuery}
        setSearchBy={setSearchBy}
        options={options}
        setSort={setSort}
        sortDirection={sortDirection}
        sortData={sortData}
      >
        <div className="toggle_wrapper">
          <div className="checkbox-wrapper-6">
            <input
              className="tgl tgl-light"
              id="cb1-6"
              type="checkbox"
              onChange={onlyShowSuccess}
              value={showOnlySuccess}
            />
            <label className="tgl-btn" htmlFor="cb1-6" />
          </div>
          <p className="toggle_label">Only SUCCESS</p>
        </div>
      </SearchBar>

      <Modal
        title="Create Refund Link"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <NewRefund
          onClose={() => setIsOpen(false)}
          customerList={verifiedCustomers}
          uid={user.uid}
          merchant={user.displayName}
        />
      </Modal>

      <div style={{ width: "100%", minWidth: 900 }}>
        <TableContainer className="table_conatiner" sx={{ height: 500 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={"30%"}>
                  <p className="table-title">Date & Time</p>
                </TableCell>
                <TableCell width={"20%"}>
                  <p className="table-title">Account Number</p>
                </TableCell>
                <TableCell width={"20%"}>
                  <p className="table-title">Reference</p>
                </TableCell>
                <TableCell width={"15%"}>
                  <p className="table-title">Amount</p>
                </TableCell>
                <TableCell width={"15%"}>
                  <p className="table-title">Status</p>
                </TableCell>

                <TableCell width={1}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refundLinks &&
                filteredItems.slice(pg * rpg, pg * rpg + rpg).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <p className="format-row">{row.tableDate}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.wholesaleAccountNumber}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.reference}</p>
                    </TableCell>
                    <TableCell>
                      <p>£{row.amount}</p>
                    </TableCell>
                    <TableCell>
                      <p className={row.status}>{row.status}</p>
                    </TableCell>
                    <TableCell align="right">
                      <div className="customer-inputs">
                        {row.status === "SUCCESS" ? (
                          <FiMinus className="copy_icon tbl-icon" />
                        ) : (
                          <FiCopy
                            className="copy_icon tbl-icon"
                            onClick={() => copyUrl(row.id)}
                          />
                        )}
                        <FiTrash2
                          onClick={() => deleteDocument("refunds", row.id)}
                          className="delete-icon tbl-icon"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {refundLinks && (
          <div className="table_options">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rpg}
              page={pg}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RefundLinks;
