import { useState } from "react";
import { formatAccountNumber, createCustomer } from "../actions/createCustomer";
import { UserAuth } from "../../../context/AuthContext";

export const NewCustomer = ({ onClose }) => {
  const { user } = UserAuth();
  const [fullName, setFullName] = useState("");
  const [wholesaleAccountNumber, setWholesaleAccountNumber] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  async function onSubmit(e) {
    e.preventDefault();
    const accNum = formatAccountNumber(wholesaleAccountNumber);
    await createCustomer(fullName, accNum, email, user.uid, phone);
    onClose();
  }

  return (
    <form onSubmit={onSubmit} className="modalFormContainer">
      <label>Customer Name *</label>
      <input
        className="modalFormInputBox"
        placeholder="Customer Name"
        type="string"
        onChange={(e) => setFullName(e.target.value)}
        required
        autoFocus
      />
      <label>Customer Account Number *</label>

      <input
        className="modalFormInputBox"
        placeholder="Customer Account Number"
        type="string"
        onChange={(e) => setWholesaleAccountNumber(e.target.value)}
        required
      />

      <label>Customer Mobile Number</label>
      <p className="modalFormAdditionalInfo">
        Please ensure you are entering a UK mobile number (+44). Check the
        preview number below when it appears to ensure you have entered the
        number correctly. For example, if the customer's mobile number is
        07812345678, you would add it as 7812345678. Alternatively leave blank.
      </p>
      <p className="modalFormAdditionalInfo">Preview Number: +44 {phone}</p>

      <input
        className="modalFormInputBox"
        placeholder="0123456789"
        type="tel"
        pattern="[0-9]{10}"
        onChange={(e) => setPhone(e.target.value)}
        maxLength={10}
      />

      <label>Customer Email Address *</label>

      <input
        className="modalFormInputBox"
        placeholder="Customer Email"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      <button type="submit" className="modalFormInputButton">
        Add New Customer
      </button>
    </form>
  );
};
