import { FiTrash2 } from "react-icons/fi";

const EmployeeItems = ({ employees, deleteEmployee }) => {
  return (
    <div className="settings-form-wrapper">
      <h4 className="settings-form-title">Employee List</h4>
      <ol className="settings-employee-list">
        {employees.map((employee) => {
          return (
            <li key={employee.id} className="employee-list">
              <p>
                {employee.name} - {employee.initials}
              </p>
              <FiTrash2
                className="delete-icon"
                onClick={() => deleteEmployee(employee.id)}
              />
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default EmployeeItems;
