import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

export const rebrandlyApi = async (linkId, link) => {
  const onCallFunction = httpsCallable(functions, "a2a-updateQr");
  const id = toast.loading("Please wait...");
  try {
    const result = await onCallFunction({ link: link, linkId: linkId });
    if (result.data.status === "active") {
      toast.update(id, {
        render: result.data.info,
        type: "success",
        autoClose: 3000,
        closeOnClick: true,
        isLoading: false,
      });
    } else {
      toast.update(id, {
        render: result.data.info,
        type: "failed",
        autoClose: 3000,
        closeOnClick: true,
        isLoading: false,
      });
    }
  } catch (err) {
    toast.update(id, {
      render: "Error in updating QR Code.",
      type: "failed",
      autoClose: 3000,
      closeOnClick: true,
      isLoading: false,
    });
  }
};
