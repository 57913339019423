import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

import { ComponentToPrint } from "../components/Receipt";
import { UserAuth } from "../../../context/AuthContext";
import { FiPrinter } from "react-icons/fi";

export const PrintButton = (props) => {
  const { user } = UserAuth();
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => <FiPrinter className="tbl-icon print_icon" />}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <ComponentToPrint
          name={user.displayName}
          props={props}
          ref={componentRef}
        />
      </div>
    </>
  );
};
