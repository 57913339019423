import "../styles.css";
import "./styles.css";
import { useEffect, useState } from "react";
import Carousel from "./components/Carousel";
import { UserAuth } from "../../context/AuthContext";
import { getLastTwoDaysOfLinks } from "../../firebase/firestore";
import { contentLeft, contentRight } from "./components/CarouselContent";

const Home = () => {
  const { user } = UserAuth();
  const [todaysValue, setTodaysValue] = useState(0);
  const [todaysNumber, setTodaysNumber] = useState(0);
  const [yesterdaysValue, setYesterdaysValue] = useState(0);
  const [yesterdaysNumber, setYesterdaysNumber] = useState(0);
  const date = new Date();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  useEffect(() => {
    async function getLastTwoDaysPayments() {
      const links = await getLastTwoDaysOfLinks(user.uid);

      if (!links) return;
      setTodaysValue(0);
      setTodaysNumber(0);
      setYesterdaysNumber(0);
      setYesterdaysValue(0);
      links.forEach((link) => {
        if (link.status !== "SUCCESS") return;

        const linkDate = new Date(
          +link.timeStamp.seconds * 1000
        ).toDateString();

        if (linkDate === today.toDateString()) {
          setTodaysValue((prev) => prev + +link.amount);
          setTodaysNumber((prev) => prev + 1);
        }

        if (linkDate === yesterday.toDateString()) {
          setYesterdaysValue((prev) => prev + +link.amount);
          setYesterdaysNumber((prev) => prev + 1);
        }
      });
    }

    getLastTwoDaysPayments();
  }, []);

  return (
    <>
      <div className="totals_container">
        <div className="total_box">
          <img
            className="home_logo"
            src={
              user.photoURL ||
              "https://bucket.mlcdn.com/a/2865/2865955/images/6016a8f6c5c0e6c6fe037c30f1754deef07d986a.png"
            }
          />
          <div className="home_profile_text">
            <h4>Your Account Details</h4>
            <p className="small_title">Display Name:</p>
            <p className="merchant_detail_text">{user.displayName}</p>
            <p className="small_title">Email:</p>
            <p className="merchant_detail_text">{user.email}</p>
          </div>
        </div>
        <div className="total_box">
          <div className="today_summary_container">
            <h4>Today's Summary - {date.toLocaleDateString()}</h4>
            <div className="today_stats_conatiner">
              <div className="today_stat">
                <p>Total Amount</p>
                <h2 className="stat">
                  {Intl.NumberFormat(
                    "en-GB", // BCP 47 language tag
                    {
                      style: "currency", // we want a currency
                      currency: "GBP", // ISO 4217 currency code
                    }
                  ).format(todaysValue)}
                </h2>
              </div>
              <div className="today_stat">
                <p>Total Number</p>
                <h2 className="stat">{todaysNumber}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="total_box">
          <div className="today_summary_container">
            <h4>Yesterday's Summary - {yesterday.toLocaleDateString()}</h4>
            <div className="today_stats_conatiner">
              <div className="today_stat">
                <p>Total Amount</p>
                <h2 className="stat">
                  {Intl.NumberFormat(
                    "en-GB", // BCP 47 language tag
                    {
                      style: "currency", // we want a currency
                      currency: "GBP", // ISO 4217 currency code
                    }
                  ).format(yesterdaysValue)}
                </h2>
              </div>
              <div className="today_stat">
                <p>Total Number</p>
                <h2 className="stat">{yesterdaysNumber}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="news_container">
        <h3>Latest News</h3>
        <div className="carousel_container">
          <Carousel images={contentLeft} />
          <Carousel images={contentRight} />
        </div>
      </div>

      <div className="news_container">
        <h3>Key Features:</h3>
        <ul className="list-container">
          <li className="list-item">Set up and manage customers.</li>
          <li className="list-item">
            Generate and monitor account to account payments.
          </li>
          <li className="list-item">
            Verify and refund to customer bank accounts.
          </li>
          <li className="list-item">
            Manage and redirect a static QR code. This can be used for taking
            payment at the point of sale.
          </li>
          <li className="list-item">
            Our dashboard is powered by open banking technology, which means
            that transactions are securely processed and authenticated through
            your bank. This ensures that your payments are safe, reliable and
            processed quickly.
          </li>
          <li className="list-item">
            With our user-friendly interface, you can easily manage your
            payments, view transaction history and keep track of your customers.
            Plus, our payment links can be fully customized to match your
            branding, giving your customers a seamless payment experience.
          </li>
        </ul>
      </div>
    </>
  );
};

export default Home;
