import { useState } from "react";
import "../customer.css";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { showToast } from "../../../utils/toastModal";

const EditCustomer = ({ onClose, selectedCustomer }) => {
  const [wholesaleAccount, setWholesaleAccount] = useState(
    selectedCustomer.wholesaleAccount
  );
  const [name, setName] = useState(selectedCustomer.name);
  const [email, setEmail] = useState(selectedCustomer.email);
  const [phone, setPhone] = useState(selectedCustomer.phone);

  async function onSubmit(e) {
    e.preventDefault();

    const docToUpdate = doc(db, "psu", selectedCustomer.id);

    try {
      if (!phone) {
        await updateDoc(docToUpdate, {
          wholesaleAccount: wholesaleAccount.toUpperCase(),
          name,
          email,
          phone: "",
        });
      } else {
        await updateDoc(docToUpdate, {
          wholesaleAccount: wholesaleAccount.toUpperCase(),
          name,
          email,
          phone,
        });
      }
      onClose();
    } catch (error) {
      showToast(false, error.message);
    }
  }

  return (
    <form onSubmit={onSubmit} className="modalFormContainer">
      <label>Customer Name *</label>
      <input
        className="modalFormInputBox"
        placeholder="Customer Name"
        type="string"
        onChange={(e) => setName(e.target.value)}
        required
        autoFocus
        value={name}
      />

      <label>Customer Account Number *</label>

      <input
        className="modalFormInputBox"
        placeholder="Customer Account Number"
        type="string"
        onChange={(e) => setWholesaleAccount(e.target.value)}
        required
        value={wholesaleAccount}
      />

      <label>Customer Mobile Number</label>
      <p className="modalFormAdditionalInfo">
        Please ensure you are entering a UK mobile number (+44). Check the
        preview number below when it appears to ensure you have entered the
        number correctly. For example, if the customer's mobile number is
        07812345678, you would add it as 7812345678. Alternatively leave blank.
      </p>
      <p className="modalFormAdditionalInfo">Preview Number: +44 {phone}</p>

      <input
        className="modalFormInputBox"
        placeholder="Mobile Number"
        type="tel"
        pattern="[0-9]{10}"
        onChange={(e) => setPhone(e.target.value)}
        maxLength={10}
        value={phone}
      />

      <label>Customer Email Address *</label>

      <input
        className="modalFormInputBox"
        placeholder="Customer Email"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        required
        value={email}
      />

      <button type="submit" className="modalFormInputButton">
        Edit Customer Record
      </button>
    </form>
  );
};

export default EditCustomer;
