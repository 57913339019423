import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { showToast } from "../utils/toastModal";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      showToast(true, "Logged In Successfully.");
      navigate("/dashboard/home");
    } catch (e) {
      if (e.message === "Firebase: Error (auth/user-not-found).") {
        showToast(false, "Invalid Email or Password. Try again.");
      } else {
        showToast(false, "There Has Been An Error In Logging You In.");
      }
      console.log(e.message);
      console.log(e);
    }
  };
  return (
    <div className="login-container">
      <div className="flex-col">
        <h2>A2APay Dashboard</h2>
        <p>Please login in with your email and password.</p>
        <form className="login-input-gap" onSubmit={handleSubmit}>
          <input
            placeholder="Email..."
            onChange={(e) => setEmail(e.target.value)}
            className="pop-up-input-box"
            type="email"
          />
          <input
            placeholder="Password..."
            onChange={(e) => setPassword(e.target.value)}
            className="pop-up-input-box"
            type="password"
          />
          <button className="pop-up-btn btn-green">Enter</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
