import React from "react";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div className="print-container">
        <h2 className="title">{this.props.name} Receipt</h2>
        <h4>EchoPay Payment</h4>
        <table className="settings-table">
          <tbody>
            <tr className="settings-table-row">
              <th className="settings-table-header">Title</th>
              <th className="settings-table-header">Details</th>
            </tr>
            <tr className="settings-table-row">
              <td className="settings-table-header">Customer Account Number</td>
              <td className="settings-table-header">
                {this.props.props.props.wholesaleAccountNumber}
              </td>
            </tr>
            <tr className="settings-table-row">
              <td className="settings-table-header">Amount</td>
              <td className="settings-table-header">
                £{this.props.props.props.amount}
              </td>
            </tr>
            <tr className="settings-table-row">
              <td className="settings-table-header">Status</td>
              <td className="settings-table-header">
                {this.props.props.props.status}
              </td>
            </tr>
            <tr className="settings-table-row">
              <td className="settings-table-header">
                Bank Statement Reference
              </td>
              <td className="settings-table-header">
                {this.props.props.props.reference}
              </td>
            </tr>
            <tr className="settings-table-row">
              <td className="settings-table-header">Description</td>
              <td className="settings-table-header">
                {this.props.props.props.description}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
