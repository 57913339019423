import { useEffect, useState } from "react";
import { Loader } from "../enduser/components/Loader";
import MoreInfo from "./MoreInfo";
import { cloudFunction } from "./functions";

const PisLinkRequest = () => {
  const [data, setData] = useState({
    status: "No Status Found",
    info: "No Info Found",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const docId = params.get("id");
    if (!docId) {
      setLoading(false);
      return;
    }
    async function callCloudFunction() {
      const [data, error] = await cloudFunction({ docId }, "pis-linkrequest");

      if (error) {
        setData(error);
        setLoading(false);
        return;
      }

      if (data.url) {
        window.location.href = data.url;
      } else {
        setData(data);
        setLoading(false);
        return;
      }
    }
    callCloudFunction();
  }, []);

  return loading ? <Loader /> : <MoreInfo data={data} />;
};

export default PisLinkRequest;
