import { toast } from "react-toastify";

export const showToast = (status, message) => {
  if (status) {
    toast.success(message, {
      pauseOnHover: true,
    });
  } else {
    toast.error(message, {
      pauseOnHover: true,
    });
  }
};
