import React from "react";
import ReactDOM from "react-dom";
import { FiXSquare } from "react-icons/fi";
import "./modal.css";

export const Modal = ({ open, children, onClose, title }) => {
  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <div className="overlay" />
      <div className="modal-style">
        <div className="modal-close-container">
          <h3>{title}</h3>
          <FiXSquare className="modal-close" onClick={onClose} />
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
