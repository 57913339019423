import {
  deleteDoc,
  doc,
  onSnapshot,
  query,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

export const deleteDocument = async (collection, docId) => {
  await deleteDoc(doc(db, collection, docId));
};

export const getAllMultipleDocsFromCol = async (col, uid, setData) => {
  const collectionQuery = query(collection(db, col), where("uid", "==", uid));

  const unsubscribe = onSnapshot(collectionQuery, async (snapshot) => {
    let data = [];
    for (const documentSnapshot of snapshot.docs) {
      const docData = documentSnapshot.data();
      const id = documentSnapshot.id;

      await data.push({
        ...docData,
        id,
      });
    }
    setData(data);
  });

  return unsubscribe;
};
