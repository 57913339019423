import React, { useState } from "react";
import Papa from "papaparse";
import "./csvuploader.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";

import { createNewPaymentLink } from "../../Customers/actions/createCustomer";

import { UserAuth } from "../../../context/AuthContext";
import { Rings } from "react-loader-spinner";

const CsvUploader = ({ setIsOpen }) => {
  const { user } = UserAuth();
  const [csvData, setCsvData] = useState(null);
  const [objectProperties, setObjectProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        // Initialize objectProperties with the column names from the CSV file
        const firstRow = results.data[0];
        const columns = Object.keys(firstRow);
        const initialObjectProperties = columns.map((column) => ({
          column,
          property: "",
          exclude: false,
        }));
        setObjectProperties(initialObjectProperties);

        setCsvData(results.data);
      },
    });
  };

  const handlePropertyChange = (column, e) => {
    const { name, value } = e.target;
    setObjectProperties((prevProperties) =>
      prevProperties.map((prop) =>
        prop.column === column ? { ...prop, [name]: value } : prop
      )
    );
  };

  const handleSaveToDatabase = async () => {
    // Map csvData columns to object properties based on the user-selected mappings
    const mappedData = csvData.map((row) => {
      const mappedRow = {};
      objectProperties.forEach(({ column, property, exclude }) => {
        if (exclude) return;
        mappedRow[property] = row[column];
      });
      return mappedRow;
    });
    setLoading(true);
    let totalBulkPaymentsInCsv = 0;
    let totalAddedToDatabase = 0;
    mappedData.forEach((data) => {
      totalBulkPaymentsInCsv = totalBulkPaymentsInCsv + 1;
      if (
        !data.wholesaleAccountNumber ||
        !data.email ||
        !data.reference ||
        !data.description ||
        !data.amount
      ) {
        console.log("Not added");
        return;
      }

      const linkPayload = {
        email: data.email,
        reference: data.reference.substring(0, 14),
        description: data.description.substring(0, 30),
        amount: +data.amount,
        wholesaleAccountNumber: data.wholesaleAccountNumber.toUpperCase(),
        uid: user.uid,
        sendMethod: "email",
        status: "PENDING",
        merchant: user.displayName,
        counter: 0,
        currency: "GBP", // Could cause issues if we introduce EUR for another merchant
      };

      totalAddedToDatabase = totalAddedToDatabase + 1;

      async function createNewPayment() {
        const [newDoc, newDocError] = await createNewPaymentLink(linkPayload);

        if (newDocError) {
          console.log("error in creating link in database");
          return;
        }
      }
      createNewPayment();
    });

    console.log(totalAddedToDatabase);

    const toastMessage =
      "Total Uploaded: " +
      totalAddedToDatabase +
      " out of " +
      totalBulkPaymentsInCsv;

    toast.success(toastMessage, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { backgroundColor: "#FFF", color: "#000" },
    });

    setLoading(false);
    setCsvData(null);
    setObjectProperties([]);
    setIsOpen();
  };

  return (
    <div className="modalFormContainer">
      <p>
        This is a way for you to quickly upload payments using a CSV file. The
        columns you will need are Account Number, Email, Amount (as a number),
        Reference and Description. We recommend downloading our template CSV
        which can be found on our tips and tricks guide we have set up on the{" "}
        <a href="https://echopay.co.uk/a2apay-guides" target="_blank">
          EchoPay website.
        </a>
      </p>
      <input
        className="csv_input"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleFileUpload}
      />

      {objectProperties.length > 0 && (
        <>
          <p>
            Please match the columns of your CSV file with the Payment Property.
          </p>
          <TableContainer
            className="table_conatiner"
            style={{ borderRadius: 4 }}
            sx={{ maxHeight: "450px" }}
          >
            <Table size="small" stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"50%"}>
                    <p className="table-title">CSV Column</p>
                  </TableCell>
                  <TableCell width={"40%"} align="center">
                    <p className="table-title">Payment Property</p>
                  </TableCell>
                  <TableCell width={"10%"} align="center">
                    <p className="table-title">Exclude</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectProperties.map(({ column, property, exclude }) => (
                  <TableRow key={column}>
                    <TableCell component="th" scope="row">
                      <td>{column}</td>
                    </TableCell>
                    <TableCell align="center">
                      <select
                        className="modalFormInputBox"
                        name="property"
                        value={property}
                        onChange={(e) => handlePropertyChange(column, e)}
                      >
                        <option value="">Select an Option</option>
                        <option value="wholesaleAccountNumber">
                          Account Number
                        </option>
                        <option value="email">Email</option>
                        <option value="reference">Reference</option>
                        <option value="description">Description</option>
                        <option value="amount">Amount</option>
                      </select>
                    </TableCell>
                    <TableCell align="center">
                      <input
                        type="checkbox"
                        name="exclude"
                        checked={exclude}
                        onChange={(e) => handlePropertyChange(column, e)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <button
            className="modalFormInputButton"
            onClick={handleSaveToDatabase}
            disabled={loading}
          >
            {loading ? (
              <div className="csv_loader">
                <Rings
                  height="30"
                  width="30"
                  color="#063970"
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
            ) : (
              "Upload Bulk Payments"
            )}
          </button>
        </>
      )}
    </div>
  );
};

export default CsvUploader;
