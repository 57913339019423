import { db } from "../../../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export const createNewRefundLink = async (
  customerDetails,
  amount,
  reference,
  uid,
  merchant
) => {
  try {
    const newDoc = await addDoc(collection(db, "refunds"), {
      accountDetails: {
        accountHolder: customerDetails.accountDetails.accountHolder,
        accountNumber: customerDetails.accountDetails.accountNumber,
        sortCode: customerDetails.accountDetails.sortCode,
      },
      merchant: merchant,
      amount: +amount,
      counter: 0,
      reference: reference,
      status: "PENDING",
      timeStamp: serverTimestamp(),
      uid: uid,
      wholesaleAccountNumber: customerDetails.wholesaleAccount,
    });
    return newDoc;
  } catch (error) {
    console.error(error);
    return null;
  }
};
