import { useState } from "react";
import Select from "react-select";
import "../customer.css";
import { toast } from "react-toastify";
import { createNewPaymentLink } from "../actions/createCustomer";

export const NewPayment = ({
  onClose,
  selectedCustomer,
  merchantDetails,
  employees,
  uid,
}) => {
  const [reference, setReference] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [employee, setEmployee] = useState("");
  const [paymentRequestType, setPaymentRequestType] = useState("");

  const sendEmail = merchantDetails.sendEmail;

  const onSubmit = async (e) => {
    e.preventDefault();

    let newDescription = description;
    if (employee) {
      newDescription = `(${employee}) ${description}`;
    }

    const linkPayload = {
      email: selectedCustomer.email,
      reference: reference,
      description: newDescription,
      amount: +amount,
      wholesaleAccountNumber: selectedCustomer.wholesaleAccount,
      uid: uid,
      sendMethod:
        sendEmail || paymentRequestType === "whatsapp"
          ? paymentRequestType
          : "none",
      status: "PENDING",
      merchant: merchantDetails.accountDetails.accountHolder,
      counter: 0,
      currency: merchantDetails.currency,
    };

    if (selectedCustomer.phone) {
      linkPayload["phone"] = selectedCustomer.phone;
    }

    const [newDoc, newDocError] = await createNewPaymentLink(linkPayload);

    if (newDocError) {
      toast.error(newDocError, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    console.log("DOC ID" + newDoc);

    const toastMessage = `🚀 Payment Link Created`;

    toast.success(toastMessage, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { backgroundColor: "#13aa52" },
    });

    onClose();
  };

  return (
    <form onSubmit={onSubmit} className="modalFormContainer">
      <label>Customer Selected</label>
      <input
        className="modalFormInputBox"
        type="string"
        required
        value={selectedCustomer.wholesaleAccount}
        disabled
      />

      <label>Payment Reference *</label>
      <p
        className="modalFormAdditionalInfo quickAdd"
        onClick={() => setReference(selectedCustomer.wholesaleAccount)}
      >
        Click here to quickly add customer account number as reference.
      </p>
      <input
        className="modalFormInputBox"
        placeholder="This will appear on your bank statement"
        type="text"
        onChange={(e) => setReference(e.target.value)}
        maxLength={12}
        value={reference}
        required
        autoFocus
      />

      <label>Payment Description *</label>

      <input
        className="modalFormInputBox"
        placeholder="Description"
        type="text"
        onChange={(e) => setDescription(e.target.value)}
        maxLength={30}
        required
      />

      {employees.length > 0 && (
        <>
          <label>Select Employee *</label>
          <Select
            className="searchSelection"
            onChange={(e) => setEmployee(e.value)}
            options={employees}
            placeholder="Select Employee"
            name="employeeSelect"
            required
          />
        </>
      )}

      <label>Payment Amount *</label>

      <input
        className="modalFormInputBox"
        placeholder="Amount"
        type="number"
        onChange={(e) => setAmount(e.target.value)}
        required
        step="0.01"
      />

      <button
        onClick={() => setPaymentRequestType("email")}
        type="submit"
        className="modalFormInputButton"
      >
        {merchantDetails.sendEmail ? "Send Email" : "Create Payment Link"}
      </button>

      <button
        onClick={() => {
          setPaymentRequestType("whatsapp");
        }}
        type="submit"
        className="modalFormInputButton"
        disabled={!selectedCustomer.phone}
      >
        Send WhatsApp
      </button>
    </form>
  );
};
