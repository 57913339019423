import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

export const v2qrCreatePayment = async (reference, amount, merchantUid) => {
  const cloudFunction = httpsCallable(functions, "pis-tokenrequest");

  try {
    const result = await cloudFunction({
      reference,
      amount,
      uid: merchantUid,
    });

    console.log("Result", result);
    return result;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};
