import { ThreeDots } from "react-loader-spinner";
import "./Loader.css";

export const Loader = () => {
  return (
    <div className="loader">
      <img src={require("../../assets/ep-logo.png")} className="ep-logo" />
      <div className="dots">
        <ThreeDots
          height="45"
          width="45"
          radius="9"
          color="#000"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      </div>
    </div>
  );
};

export const Status = ({ status, info }) => {
  return (
    <div className={`statusContainer ${status}-`}>
      <p className="status">{status}</p>
      <p className="info">{info}</p>
    </div>
  );
};
