import React from "react";

const SettingsForm = ({ onSubmit, children, title }) => {
  return (
    <form className="settings-form-wrapper" onSubmit={onSubmit}>
      <h4 className="settings-form-title">{title}</h4>
      {children}
      <div className="settings-form-btn-wrapper">
        <button type="submit" className="button-37">
          Submit
        </button>
      </div>
    </form>
  );
};

export default SettingsForm;
