import { Route, Routes } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import "./styles.css";
import { UserAuth } from "../context/AuthContext";
import { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import Home from "./Home/Home";
import PaymentLinks from "./Payments/PaymentLinks";
import RefundLinks from "./Refunds/RefundLinks";
import DynamicQR from "./DynamicQR/DynamicQR";
import Customers from "./Customers/Customers";
import Settings from "./Settings/Settings";
import NotFound from "../general/NotFound";
import KineticTerminal from "./KineticTerminal/KineticTerminal";

// TODO: Get merchant details and save it to state.
// TODO: Do the same for the employees.
import {
  getMerchantEmployees,
  getMerchantDetails,
} from "../firebase/firestore";
// TODO: Remove all of the state for customer and payment links.

function DashboardRoutes() {
  const { user } = UserAuth();
  const [merchantDetails, setMerchantDetails] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [verifiedCustomers, setVerifiedCustomers] = useState([]);

  async function getAllMerchantDetails() {
    const uid = user.uid;

    const merchant = await getMerchantDetails(uid);

    setMerchantDetails(merchant);

    const employees = await getMerchantEmployees(uid);

    setEmployees(employees);
  }

  useEffect(() => {
    if (user) {
      getAllMerchantDetails();
    }
  }, [user]);

  return (
    <div className="dashboard_container">
      <Sidebar />
      {!merchantDetails ? (
        <div className="pageLoaderWrapper">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#063970"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        <main className="main_content_container">
          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="/customers"
              element={
                <Customers
                  merchantDetails={merchantDetails}
                  employees={employees}
                  setVerifiedCustomers={setVerifiedCustomers}
                />
              }
            />
            <Route path="/payment-links" element={<PaymentLinks />} />
            <Route path="/dynamic-qr" element={<DynamicQR />} />
            <Route path="/payment-terminal" element={<KineticTerminal />} />
            <Route
              path="/refund-links"
              element={<RefundLinks verifiedCustomers={verifiedCustomers} />}
            />
            <Route
              path="/settings"
              element={
                <Settings
                  merchantDetails={merchantDetails}
                  employees={employees}
                  getAllMerchantDetails={getAllMerchantDetails}
                />
              }
            />
          </Routes>
        </main>
      )}
    </div>
  );
}

export default DashboardRoutes;
