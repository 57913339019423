export const Button = (props) => {
  const { title, func } = props;
  return (
    <div className="btn-box">
      <button
        type="submit"
        className="modalFormInputButton"
        style={{ paddingRight: 10, paddingLeft: 10 }}
        onClick={() => {
          func();
        }}
      >
        {title}
      </button>
    </div>
  );
};
