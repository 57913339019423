import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export const cloudFunction = async (data, functionName) => {
  console.log(data);
  const cloudFunction = httpsCallable(functions, functionName);
  try {
    const result = await cloudFunction({ ...data });
    return [result.data, null];
  } catch (error) {
    console.error(error.code);
    return [null, { status: error.code, info: error.message }];
  }
};
