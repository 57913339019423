import Select from "react-select";

const options = [
  { value: "GBP", label: "GBP (£)" },
  { value: "EUR", label: "EUR (€)" },
];

const emailoptions = [
  { value: true, label: "Send" },
  { value: false, label: "Don't Send" },
];

export const CurrencyOptions = ({ currency, setCurrency }) => {
  return (
    <>
      <Select
        className="searchSelection"
        onChange={(e) => setCurrency(e.value)}
        options={options}
        placeholder={currency ? currency : "Select Currency"}
        name="currencySelect"
        required
      />
    </>
  );
};

export const SendEmailOptions = ({ sendEmail, setSendEmail }) => {
  return (
    <>
      <Select
        className="searchSelection"
        onChange={(e) => setSendEmail(e.value)}
        options={emailoptions}
        placeholder={sendEmail ? "Send" : "Don't Send"}
        name="currencySelect"
        required
      />
    </>
  );
};
