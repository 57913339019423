import { useEffect, useState } from "react";
import { v2qrCreatePayment } from "./cloudFunction";
import { Loader } from "../components/Loader";
import "./uws.css";

function UwsPayment() {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [branch, setBranch] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    setBranch("HE-w7WlqmcB46ZLAsXcH1Uqy7PIreI3");
  }, []);

  const createWebAppLink = async () => {
    if (!branch) {
      alert("Please select a branch.");
      return;
    }
    if (!accountNumber) {
      alert("Please enter your Account Number");
      return;
    }
    if (!amount) {
      alert("Please enter an amount to pay.");
      return;
    }
    if (amount < 0.01) {
      alert("Please enter an amount higher than £0.01");
      return;
    }
    setLoading(true);
    const [branchId, merchantId] = branch.split("-");
    let ref = "EP " + branchId + " " + accountNumber;
    ref = ref.toUpperCase();

    try {
      const res = await v2qrCreatePayment(ref, amount, merchantId);
      if (res.data.url) {
        window.location.href = res.data.url;
      } else {
        alert(res.data.status);
        setLoading(false);
      }
    } catch (err) {
      alert("Error, please try again.");
      setLoading(false);
    }
  };

  return loading ? (
    <>
      <Loader />
      <div className="bankCheck">
        <p className="bankCheckTitle">
          Please check the bank you have selected. Personal and Business
          accounts have a seperate selection box.
        </p>
        <img
          className="bankCheckImg"
          src={require("../../assets/bank-selection.png")}
        />
      </div>
    </>
  ) : (
    <div className="uws-container">
      <img src={require("../../assets/uws.jpg")} className="uws-logo" />
      <h3 className="uws-title">Welcome, please enter your details below.</h3>
      <div className="uws-inputs">
        <label className="uws-input-title">Account Number</label>
        <input
          onChange={(e) => setAccountNumber(e.target.value)}
          maxLength={8}
        />
        <label className="uws-input-title">UWS Branch</label>
        <select
          onChange={(e) => {
            setBranch(e.target.value);
          }}
        >
          <option key={"HE"} value={"HE-w7WlqmcB46ZLAsXcH1Uqy7PIreI3"}>
            Head Office
          </option>
          <option key={"MA"} value={"MA-5cP7cswJiBTQ1ya4vzvhCPImZfm2"}>
            Maxwell Road
          </option>
          <option key={"QU"} value={"QU-icTypCDk7aev17gFh7AzpFd37pu1"}>
            Queenslie
          </option>
          <option key={"M9"} value={"M9-9ByBJS8Hf7Wh0zcnydWf7hWMYMG3"}>
            M9
          </option>
        </select>

        <label className="uws-input-title">Amount (£0.00)</label>
        <input
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
        <button className="uws-btn" onClick={createWebAppLink}>
          Enter
        </button>
      </div>
    </div>
  );
}

export default UwsPayment;
