import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

export const updateKineticCollection = async (
  id,
  echopay,
  amount,
  surcharge,
  newDoc
) => {
  const docToUpdate = doc(db, "KINETIC_TERMINAL", id);
  try {
    await updateDoc(docToUpdate, {
      echopay: echopay,
      amount: +amount,
      surcharge: surcharge,
      docId: newDoc,
      show: true,
    });
    return [true, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

export const changeShowToFalse = async (id) => {
  const docToUpdate = doc(db, "KINETIC_TERMINAL", id);
  try {
    await updateDoc(docToUpdate, {
      show: false,
    });
    return [true, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};
