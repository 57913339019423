import { useState } from "react";
import { createNewRefundLink } from "../actions/firestore";
import Select from "react-select";
import { useEffect } from "react";

export const NewRefund = ({ onClose, uid, merchant, customerList }) => {
  const [options, setOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    let list = [];
    customerList.map((customer) => {
      if (customer.verified) {
        list.push({
          value: customer.name,
          label: customer.wholesaleAccount,
          ...customer,
        });
      }
    });
    setOptions(list);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    await createNewRefundLink(
      selectedCustomer,
      amount,
      reference,
      uid,
      merchant
    );
    onClose();
  };

  return (
    <form onSubmit={onSubmit} className="modalFormContainer">
      <label>Select A Customer*</label>
      <Select
        className="select-box"
        onChange={(e) => setSelectedCustomer(e)}
        options={options}
        isSearchable={true}
        name="customer"
        required
      />

      <label>Payment Reference *</label>

      <input
        className="modalFormInputBox"
        placeholder="This will appear on your bank statement"
        type="text"
        onChange={(e) => setReference(e.target.value)}
        maxLength={12}
        value={reference}
        required
        autoFocus
      />

      <label>Payment Amount *</label>

      <input
        className="modalFormInputBox"
        placeholder="Amount"
        type="number"
        onChange={(e) => setAmount(e.target.value)}
        required
        step="0.01"
      />

      <button type="submit" className="modalFormInputButton">
        Create New Refund
      </button>
    </form>
    /*
    <>
      
      <div className="customer-inputs">
        <div className="customer-input">
          <p className="customer-input-title">Customer Name</p>
          <input
            className="input-new"
            placeholder="Name"
            type="string"
            required
            value={selectedCustomer.value || ""}
            disabled
          />
        </div>

        <div className="customer-input">
          <p className="customer-input-title">Customer Account Number</p>
          <input
            className="input-new"
            placeholder="Account Number"
            type="email"
            required
            value={selectedCustomer.label || ""}
            disabled
          />
        </div>
      </div>

      <input
        className="input-new"
        placeholder="Bank Reference"
        type="string"
        onChange={(e) => setReference(e.target.value)}
        maxLength={10}
        required
      />

      <input
        className="input-new"
        placeholder="Amount"
        type="number"
        onChange={(e) => setAmount(e.target.value)}
        required
        step="0.01"
      />

      <button onClick={handleSubmit} className="button-new">
        Create
      </button>
    </>
  );
  */
  );
};
