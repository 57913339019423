import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { getRebrandlyLinks } from "./actions/firestore";
import LinkBox from "./components/LinkBox";

const DynamicQR = () => {
  const { user } = UserAuth();
  const [rebrandlyLinks, setRebrandlyLinks] = useState();

  useEffect(() => {
    const getRedirectUrls = async () => {
      const data = await getRebrandlyLinks(user.uid);
      setRebrandlyLinks(data);
      const linkBoxes = data.map((doc) => {
        return <LinkBox key={doc.linkId} name={doc.name} linkId={doc.linkId} />;
      });
      setRebrandlyLinks(linkBoxes);
    };
    getRedirectUrls();
  }, []);

  return (
    <>
      <div className="page-header">
        <h1 className="page-title">Dynamic QR</h1>
      </div>
      <div className="link_boxes">{rebrandlyLinks}</div>
    </>
  );
};

export default DynamicQR;
