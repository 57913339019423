import { useState, useMemo, useEffect } from "react";
import { FiTrash2, FiUserCheck, FiCheck, FiEdit2 } from "react-icons/fi";
import { Modal } from "../components/Modal";
import { NewCustomer } from "./components/NewCustomer";
import { NewPayment } from "./components/NewPayment";
import Tooltip from "@mui/material/Tooltip";
import "./customer.css";
import IconButton from "@mui/material/IconButton";
import { deleteDocument } from "../../firebase/crud";
import { copyUrl } from "./actions/copyUrl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import EditCustomer from "./components/EditCustomer";
import CsvUploader from "./components/CsvUploader";
import { CSVLink } from "react-csv";
import { headers } from "./components/csvHeaders";

import { UserAuth } from "../../context/AuthContext";

// NEW HERE

import { getCustomers } from "../../firebase/firestore";
import SearchBar from "../components/SearchBar";

const options = [
  { value: "wholesaleAccount", label: "Account Number" },
  { value: "name", label: "Name" },
  { value: "email", label: "Email" },
];

const Customers = ({ merchantDetails, employees, setVerifiedCustomers }) => {
  const { user } = UserAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [query, setQuery] = useState("");
  const [searchBy, setSearchBy] = useState("wholesaleAccount");
  const [sort, setSort] = useState("wholesaleAccount");
  const [sortDirection, setSortDirection] = useState(false);
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(25);
  const [isEditCustomerOpen, setIsEditCustomerOpen] = useState(false);
  const [isCsvOpen, setIsCsvOpen] = useState(false);

  // New Stuff Here
  const [customerList, setCustomerList] = useState([]);
  const [customersLoading, setIsCustomersLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setIsCustomersLoading(true);
      async function runGetCustomers() {
        const uid = user.uid;
        const unsubscribe = await getCustomers(
          uid,
          setCustomerList,
          setIsCustomersLoading,
          setVerifiedCustomers
        );
        return () => unsubscribe();
      }
      runGetCustomers();
    }
  }, [user]);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const filteredItems = useMemo(() => {
    return customerList.filter((item) => {
      return item[searchBy].toLowerCase().includes(query.toLowerCase());
    });
  }, [customerList, query, sort, sortDirection, searchBy]);

  const sortData = async (direction) => {
    setSortDirection(direction);
    let newList;
    if (sortDirection) {
      newList = customerList.sort((a, b) =>
        a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1
      );
    } else {
      newList = customerList.sort((a, b) =>
        a[sort].toLowerCase() > b[sort].toLowerCase() ? -1 : 1
      );
    }

    setCustomerList(newList);
  };

  const handleSendPaymentBtn = (row) => {
    setSelectedCustomer(row);
    setIsPaymentOpen(true);
  };

  const handleEditCustomerBtn = (row) => {
    setSelectedCustomer(row);
    setIsEditCustomerOpen(true);
  };

  return (
    <>
      <div className="page-header">
        <h1 className="page-title">Customers</h1>
        <div className="page-header-buttons">
          <button
            onClick={() => setIsCsvOpen(true)}
            className="button-37"
            role="button"
          >
            + Upload CSV File
          </button>

          <button
            onClick={() => setIsOpen(true)}
            className="button-37"
            role="button"
          >
            + Add New Customer
          </button>
          <CSVLink
            data={customerList}
            headers={headers}
            filename="A2APay Customer list - bulk payments"
            className="button-37"
          >
            Export
          </CSVLink>
        </div>
      </div>
      <SearchBar
        query={query}
        setQuery={setQuery}
        setSearchBy={setSearchBy}
        options={options}
        setSort={setSort}
        sortDirection={sortDirection}
        sortData={sortData}
      />

      <Modal
        title="Customer CSV upload"
        open={isCsvOpen}
        onClose={() => setIsCsvOpen(false)}
      >
        <CsvUploader setIsOpen={() => setIsCsvOpen(false)} />
      </Modal>
      <Modal
        title="Add New Customer Record"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <NewCustomer onClose={() => setIsOpen(false)} />
      </Modal>

      <Modal
        title="Edit Customer Record"
        open={isEditCustomerOpen}
        onClose={() => setIsEditCustomerOpen(false)}
      >
        <EditCustomer
          onClose={() => setIsEditCustomerOpen(false)}
          selectedCustomer={selectedCustomer}
        />
      </Modal>

      <Modal
        title="Create New Payment Link"
        open={isPaymentOpen}
        onClose={() => setIsPaymentOpen(false)}
      >
        <NewPayment
          onClose={() => setIsPaymentOpen(false)}
          selectedCustomer={selectedCustomer}
          merchantDetails={merchantDetails}
          employees={employees}
          uid={user.uid}
        />
      </Modal>
      <div style={{ width: "100%", minWidth: 900 }}>
        <TableContainer className="table_conatiner" sx={{ height: 500 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={"20%"}>
                  <p className="table-title">Name</p>
                </TableCell>
                <TableCell width={"20%"}>
                  <p className="table-title">Account Number</p>
                </TableCell>
                <TableCell width={"20%"}>
                  <p className="table-title">Email</p>
                </TableCell>
                <TableCell width={"20%"}>
                  <p className="table-title">Mobile Number</p>
                </TableCell>
                <TableCell align="left" width={"10%"}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="table-title">Options</p>
                    <div>
                      <Tooltip
                        placement="top"
                        title="Capture payer bank details by sending this link."
                      >
                        <IconButton size="small">
                          <FiUserCheck />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="top" title="Edit customer record.">
                        <IconButton size="small">
                          <FiEdit2 />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.slice(pg * rpg, pg * rpg + rpg).map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <p className="format-row">{row.name}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.wholesaleAccount}</p>
                  </TableCell>
                  <TableCell>
                    <p>{row.email}</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      {row.phone
                        ? "(+44) " +
                          row.phone.slice(0, 4) +
                          " " +
                          row.phone.slice(4)
                        : "Not Found"}
                    </p>
                  </TableCell>
                  <TableCell sx={{ width: "250px" }} align="left">
                    <div className="customer-inputs">
                      {row.verified ? (
                        <FiCheck
                          className="info-icon"
                          onClick={() =>
                            alert(
                              "Account Verified. Go to the refunds page to create a new refund to this customer."
                            )
                          }
                        />
                      ) : (
                        <FiUserCheck
                          className="pay-icon tbl-icon"
                          onClick={() => copyUrl(row.id)}
                        />
                      )}

                      <FiEdit2
                        className="pay-icon tbl-icon"
                        onClick={() => handleEditCustomerBtn(row)}
                      />

                      <FiTrash2
                        onClick={() => deleteDocument("psu", row.id)}
                        className="delete-icon tbl-icon"
                      />
                      <button
                        className="payment-btn"
                        onClick={() => handleSendPaymentBtn(row)}
                      >
                        + New Payment
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="table_options">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filteredItems.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default Customers;
