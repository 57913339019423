import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoute from "./components/ProtectedRoute";
import DashboardRoutes from "./dashboard/DashboardRoutes";
import { StatusPage } from "./enduser/payments/StatusPage";
import UwsPayment from "./enduser/uws/UwsPayment";
import PisTokenRequest from "./psu/PisTokenRequest";
import PisLinkRequest from "./psu/PisLinkRequest";
import PisRefundRequest from "./psu/PisRefundRequest";
import ApiLinkRequest from "./psu/ApiLinkRequest";
import AisTokenRequest from "./psu/AisTokenRequest";
import Login from "./general/Login";
import NotFound from "./general/NotFound";

import "./styles.css";

function App() {
  return (
    <>
      <Routes>
        {/* DASHBOARD ROUTES */}
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<NotFound />} />

        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <DashboardRoutes />
            </ProtectedRoute>
          }
        />

        {/* CALLBACK AND FUNCTION ROUTES */}

        <Route path="uws" element={<UwsPayment />} />

        {/*  NEW STUFF  */}

        <Route path="pis-tokenrequest" element={<PisTokenRequest />} />
        <Route path="pis-linkrequest" element={<PisLinkRequest />} />
        <Route path="pis-refundrequest" element={<PisRefundRequest />} />
        <Route path="api-linkrequest" element={<ApiLinkRequest />} />
        <Route path="ais-tokenrequest" element={<AisTokenRequest />} />

        <Route path="status" element={<StatusPage />} />
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
