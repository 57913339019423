import { useEffect, useState, useMemo } from "react";
import { UserAuth } from "../../context/AuthContext";
import "../styles.css";
import { getPaymentLinks } from "../../firebase/firestore";
import { Modal } from "../components/Modal";
import { CSVLink } from "react-csv";
import { headers } from "./components/csvHeaders";
import SearchBar from "../components/SearchBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { FiCopy, FiTrash2 } from "react-icons/fi";
import { deleteDocument } from "../../firebase/crud";
import { PrintButton } from "./actions/Print";
import { copyUrl } from "./actions/copyUrl";
import CsvUploader from "./components/CsvUploader";

const options = [
  { value: "reference", label: "Reference" },
  { value: "wholesaleAccountNumber", label: "Account Number" },
  { value: "description", label: "Description" },
  { value: "status", label: "Status" },
  { value: "timeStamp", label: "Date & Time" },
];

const PaymentLinks = () => {
  const { user } = UserAuth();
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [query, setQuery] = useState("");
  const [searchBy, setSearchBy] = useState("reference");
  const [sort, setSort] = useState("reference");
  const [sortDirection, setSortDirection] = useState(false);
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(25);
  const [showOnlySuccess, setShowOnlySuccess] = useState(false);
  const [isCsvOpen, setIsCsvOpen] = useState(false);

  useEffect(() => {
    if (user) {
      async function runGetPayments() {
        const uid = user.uid;
        const unsubscribe = await getPaymentLinks(uid, setPaymentLinks);
        return () => unsubscribe();
      }
      runGetPayments();
    }
  }, [user]);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const filteredItems = useMemo(() => {
    if (!paymentLinks) return;
    return paymentLinks.filter((item) => {
      if (searchBy !== "timeStamp") {
        return item[searchBy].toLowerCase().includes(query.toLowerCase());
      } else {
        return item[searchBy]
          .toDate()
          .toLocaleString()
          .toLowerCase()
          .includes(query.toLowerCase());
      }
    });
  }, [paymentLinks, query, sort, sortDirection, searchBy]);

  const sortData = async (direction) => {
    setSortDirection(direction);
    let newList;
    if (sortDirection) {
      newList = paymentLinks.sort((a, b) => {
        if (sort !== "timeStamp") {
          return a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1;
        } else {
          return a[sort] - b[sort];
        }
      });
    } else {
      newList = paymentLinks.sort((a, b) => {
        if (sort !== "timeStamp") {
          return a[sort].toLowerCase() > b[sort].toLowerCase() ? -1 : 1;
        } else {
          return b[sort] - a[sort];
        }
      });
    }

    setPaymentLinks(newList);
  };

  function onlyShowSuccess() {
    if (showOnlySuccess) {
      setQuery("");
      setSearchBy("status");
    } else {
      setQuery("SUCCESS");
      setSearchBy("status");
    }
    setShowOnlySuccess((prev) => !prev);
  }

  return (
    <>
      <div className="page-header">
        <h1 className="page-title">Payments</h1>
        <div className="page-header-buttons">
          <button
            onClick={() => setIsCsvOpen(true)}
            className="button-37"
            role="button"
          >
            + Bulk Upload Payments
          </button>
          <CSVLink
            data={filteredItems}
            headers={headers}
            filename={new Date().toDateString()}
            className="button-37"
          >
            Export Filtered
          </CSVLink>
          <CSVLink
            data={paymentLinks}
            headers={headers}
            filename={new Date().toDateString()}
            className="button-37"
          >
            Export All
          </CSVLink>
        </div>
      </div>
      <SearchBar
        query={query}
        setQuery={setQuery}
        setSearchBy={setSearchBy}
        options={options}
        setSort={setSort}
        sortDirection={sortDirection}
        sortData={sortData}
      >
        <div className="toggle_wrapper">
          <div className="checkbox-wrapper-6">
            <input
              className="tgl tgl-light"
              id="cb1-6"
              type="checkbox"
              onChange={onlyShowSuccess}
              value={showOnlySuccess}
            />
            <label className="tgl-btn" htmlFor="cb1-6" />
          </div>
          <p className="toggle_label">Only SUCCESS</p>
        </div>
      </SearchBar>

      <Modal
        title="Bulk Payment CSV upload"
        open={isCsvOpen}
        onClose={() => setIsCsvOpen(false)}
      >
        <CsvUploader setIsOpen={() => setIsCsvOpen(false)} />
      </Modal>

      <div style={{ width: "100%", minWidth: 900 }}>
        <TableContainer className="table_conatiner" sx={{ height: 500 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={"26%"}>
                  <p className="table-title">Date & Time</p>
                </TableCell>
                <TableCell width={"18%"}>
                  <p className="table-title">Account Number</p>
                </TableCell>
                <TableCell width={"18%"}>
                  <p className="table-title">Reference</p>
                </TableCell>
                <TableCell width={"18%"}>
                  <p className="table-title">Description</p>
                </TableCell>
                <TableCell width={"10%"}>
                  <p className="table-title">Amount</p>
                </TableCell>
                <TableCell width={"10%"}>
                  <p className="table-title">Status</p>
                </TableCell>
                <TableCell align="center">
                  <p className="table-title">Clicks</p>
                </TableCell>
                <TableCell width={1}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.slice(pg * rpg, pg * rpg + rpg).map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <p className="format-row">{row.tableDate}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.wholesaleAccountNumber}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.reference}</p>
                    </TableCell>
                    <TableCell>
                      <p>{row.description}</p>
                    </TableCell>
                    <TableCell>
                      <p>£{row.amount}</p>
                    </TableCell>
                    <TableCell>
                      <p className={row.status}>{row.status}</p>
                    </TableCell>
                    <TableCell align="center">
                      <p>{row.counter}</p>
                    </TableCell>
                    <TableCell align="left">
                      <div className="customer-inputs">
                        {row.status === "SUCCESS" ? (
                          <PrintButton props={row} />
                        ) : (
                          <FiCopy
                            className="copy_icon tbl-icon"
                            onClick={() => copyUrl(row.id)}
                          />
                        )}
                        <FiTrash2
                          onClick={() => deleteDocument("links", row.id)}
                          className="delete-icon tbl-icon"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="table_options">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filteredItems.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentLinks;
