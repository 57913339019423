import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import "./styles.css";

import SettingsBox from "../Settings/components/SettingsBox";
import PaymentForm from "./PaymentForm";
import { getAllMultipleDocsFromCol } from "../../firebase/crud";

const KineticTerminal = () => {
  const { user } = UserAuth();
  const [terminalData, setTerminalData] = useState([]);

  const [branchPrefix, setBranchPrefix] = useState("");
  const [surchargePercentage, setSurchargePercentage] = useState(0);

  const handleBranchPrefixChange = (event) => {
    const newBranchPrefix = event.target.value;
    setBranchPrefix(newBranchPrefix);
    localStorage.setItem("branchPrefix", newBranchPrefix);
  };

  const handleSurchargeChange = (event) => {
    const surcharge = event.target.value;
    setSurchargePercentage(surcharge);
    localStorage.setItem("surcharge", surcharge);
  };

  useEffect(() => {
    const savedBranchPrefix = localStorage.getItem("branchPrefix");
    if (savedBranchPrefix) {
      setBranchPrefix(savedBranchPrefix);
    }
    const savedSurchargePercentage = localStorage.getItem("surcharge");
    if (savedSurchargePercentage) {
      setSurchargePercentage(savedSurchargePercentage);
    }
  }, []);

  useEffect(() => {
    if (!user) return; // To stop errors if no user is present, should never happen.

    async function getDataForPage() {
      const uid = user.uid;
      const unsubscribe = await getAllMultipleDocsFromCol(
        "KINETIC_TERMINAL",
        uid,
        setTerminalData
      );
      return () => unsubscribe();
    }
    getDataForPage();
  }, []);

  return (
    <>
      <div className="page-header">
        <h1 className="page-title">Payment Terminals</h1>
        <div className="terminal-header-inputs">
          <label className="terminal-form-input">Prefix:</label>
          <input
            className="inputStyle100W"
            style={{ maxWidth: 60, textAlign: "center" }}
            type="text"
            value={branchPrefix}
            onChange={handleBranchPrefixChange}
          />
          <label className="terminal-form-input">Surcharge:</label>
          <input
            className="inputStyle100W"
            style={{ maxWidth: 60, textAlign: "center" }}
            type="number"
            step="00"
            value={surchargePercentage}
            onChange={handleSurchargeChange}
          />
          <label className="terminal-form-input">%</label>
        </div>
      </div>
      <div className="terminal_form">
        {terminalData.map((terminal) => {
          return (
            <SettingsBox key={terminal.id} title={terminal.id}>
              <PaymentForm
                id={terminal.id}
                user={user}
                surchargePercentage={surchargePercentage}
                branchPrefix={branchPrefix}
              />
            </SettingsBox>
          );
        })}
      </div>
    </>
  );
};

export default KineticTerminal;
