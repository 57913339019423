import { collection, serverTimestamp, addDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { showToast } from "../../../utils/toastModal";

export async function createCustomer(fullName, accNum, email, uid, phone) {
  try {
    await addDoc(collection(db, "psu"), {
      email: email,
      name: fullName,
      timeStamp: serverTimestamp(),
      uid: uid,
      verified: false,
      wholesaleAccount: accNum,
      phone,
    });
  } catch (error) {
    console.error(error.message);
    showToast(false, error.message);
  }
}

export async function createNewPaymentLink(payload) {
  try {
    const newDoc = await addDoc(collection(db, "links"), {
      ...payload,
      timeStamp: serverTimestamp(),
    });
    return [newDoc.id, null];
  } catch (error) {
    console.error(error.message);
    return [null, error?.message];
  }
}

export const formatAccountNumber = (accNum) => {
  const formattedAccNum = accNum.replace(/\s/g, "").toUpperCase();
  return formattedAccNum;
};
