import "./styles-settings.css";

const SettingsBox = ({ children, title, subtitle }) => {
  return (
    <div className="settings-box-wrapper">
      <div className="settings-box-inner-wrapper-left">
        <h3 className="settings-box-title">{title}</h3>
        <p className="settings-box-subtitle">{subtitle}</p>
      </div>
      <div className="settings-box-inner-wrapper-right">{children}</div>
    </div>
  );
};

export default SettingsBox;
