import { useState } from "react";
import { rebrandlyApi } from "../actions/rebrandly";
import { Button } from "./Button";
import { Input } from "./Input";
import { FiRefreshCw } from "react-icons/fi";
import { createLink } from "../actions/createLink";
import { UserAuth } from "../../../context/AuthContext";

import "../dynamicQR.css";

const LinkBox = (props) => {
  const { user } = UserAuth();
  const { name, linkId } = props;
  const [amount, setAmount] = useState("");
  const [ref, setRef] = useState("");

  const onUpdateLinkPress = async (e) => {
    e.preventDefault();
    const link = await createLink(ref, amount, user.uid);
    await rebrandlyApi(linkId, link);
    setAmount("");
    setRef("");
  };

  const onResetLinkPress = async () => {
    const link = createLink("", "", user.uid);
    await rebrandlyApi(linkId, link);
    setAmount("");
    setRef("");
  };

  return (
    <div className="outline-box">
      <div className="top-outline-box">
        <h4 className="qr-title">{name}</h4>
        <div className="refresh">
          <FiRefreshCw onClick={onResetLinkPress} className="qr-icon" />
        </div>
      </div>
      <form onSubmit={onUpdateLinkPress} className="bottom-outline-box">
        <div className="input-box">
          <p className="input-label">Reference</p>
          <input
            className="input_100"
            type="text"
            placeholder={"Reference"}
            onChange={(e) => setRef(e.target.value)}
            value={ref}
            required
          />
        </div>
        <div className="input-box">
          <p className="input-label">Amount</p>
          <input
            className="input_100"
            type="text"
            placeholder={"Amount"}
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            required
          />
        </div>
        <Button title="Update" color="green" />
      </form>
    </div>
  );
};

export default LinkBox;
