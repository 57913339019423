import React, { useState } from "react";
import "./carousel.css";

const Carousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (currentImageIndex + images.length - 1) % images.length
    );
  };

  const isPreviousButtonHidden = currentImageIndex === 0;
  const isNextButtonHidden = currentImageIndex === images.length - 1;

  return (
    <div className="carousel">
      <img
        className="carousel_img"
        src={images[currentImageIndex].url}
        alt={images[currentImageIndex].alt}
      />
      <div className="carousel-caption">
        <h2>{images[currentImageIndex].title}</h2>
        <p>{images[currentImageIndex].description}</p>
      </div>
      <div className="carousel-btns">
        {!isPreviousButtonHidden && (
          <button className="carousel-btn" onClick={handlePrevImage}>
            Previous
          </button>
        )}
        {!isNextButtonHidden && (
          <button className="carousel-btn" onClick={handleNextImage}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
