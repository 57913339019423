import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import { auth } from "../firebase";
import { showToast } from "../utils/toastModal";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const changePassword = async (newPassword, currentPassword) => {
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(user, newPassword);
      showToast(true, "Password Updated.");
    } catch (error) {
      showToast(false, error?.message);
      console.log(error);
    }
  };

  const changeEmail = async (newEmail, currentPassword) => {
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updateEmail(auth.currentUser, newEmail);
      showToast(true, "Email Updated.");
    } catch (error) {
      showToast(false, error.message);
      console.log(error);
    }
  };

  const changeDisplayName = async (displayName) => {
    updateProfile(auth.currentUser, {
      displayName: displayName,
    })
      .then(() => {
        showToast(true, "Profile Updated.");
      })
      .catch((error) => {
        showToast(false, error.message);
      });
  };

  const resetPassword = (email) => {
    console.log(email);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        return { message: "Email Sent, please check your Inbox." };
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setIsLoading(false);
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        signIn,
        resetPassword,
        changePassword,
        changeEmail,
        changeDisplayName,
      }}
    >
      {!isLoading && children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
