import { FiInfo, FiArrowUp, FiArrowDown } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import Select from "react-select";
import IconButton from "@mui/material/IconButton";

const SearchBar = ({
  query,
  setQuery,
  setSearchBy,
  options,
  setSort,
  sortDirection,
  sortData,
  children,
}) => {
  return (
    <div className="searchBox">
      <input
        className="searchInput"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        type="search"
        placeholder="Search..."
      />

      <Select
        className="searchSelection"
        onChange={(e) => setSearchBy(e.value)}
        options={options}
        defaultValue={{
          value: options[0].value,
          label: options[0].label,
        }}
      />

      <Tooltip title="Search the table by these options.">
        <IconButton>
          <FiInfo />
        </IconButton>
      </Tooltip>

      <span className="vl"></span>

      <Select
        className="searchSelection"
        onChange={(e) => setSort(e.value)}
        options={options}
        defaultValue={{
          value: options[0].value,
          label: options[0].label,
        }}
      />

      <div className="arrows">
        <FiArrowUp
          className={sortDirection && "arrow-active"}
          onClick={() => sortData(true)}
        />
        <FiArrowDown
          className={!sortDirection && "arrow-active"}
          onClick={() => sortData(false)}
        />
      </div>

      <Tooltip title="Sort table by these options.">
        <IconButton>
          <FiInfo />
        </IconButton>
      </Tooltip>

      {children && <span className="vl"></span>}

      {children}
    </div>
  );
};

export default SearchBar;
