import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";
import { sidebarOptions } from "./sidebarOptions";
import "./sidebar.css";
import logo from "../../assets/a2a-logo.png";
import { UserAuth } from "../../context/AuthContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  /*
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  */

  return (
    <div className="sidebar">
      <div className="logoContainer">
        <img
          onClick={() => navigate("/dashboard/home")}
          className="logo"
          src={logo}
          alt="A2APAY"
        />

        <div className="sidebar_line_divider"></div>

        <ul className="sidebar_options_container">
          {sidebarOptions.map((option) => {
            const active = window.location.pathname === option.link;
            return (
              <li
                key={option.link}
                onClick={() => navigate(option.link)}
                className={active ? "sidebar_option_active" : "sidebar_option"}
              >
                {option.icon}
                <p>{option.title}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <a
        className="submitRequest"
        href={`https://docs.google.com/forms/d/e/1FAIpQLSeYpNEo34ofturETgFlLZtLCh8pvJ2b8RCA5iknOoXF6nPB-Q/viewform?usp=pp_url&entry.798664196=${user.displayName}&entry.1260328879=${user.email}`}
        target="_blank"
      >
        Submit a Request Ticket
      </a>
      {/* <div id="google_translate_element"></div> */}
    </div>
  );
};

export default Sidebar;
