import {
  FiCornerUpLeft,
  FiCreditCard,
  FiHome,
  FiLink,
  FiLink2,
  FiSettings,
  FiUserCheck,
  FiUsers,
} from "react-icons/fi";

export const sidebarOptions = [
  {
    title: "Dashboard Home",
    icon: <FiHome />,
    link: "/dashboard/home",
  },
  {
    title: "A2A Customers",
    icon: <FiUserCheck />,
    link: "/dashboard/customers",
  },
  {
    title: "A2A Payments",
    icon: <FiLink />,
    link: "/dashboard/payment-links",
  },
  {
    title: "A2A Refunds",
    icon: <FiCornerUpLeft />,
    link: "/dashboard/refund-links",
  },
  {
    title: "Dynamic QR Codes",
    icon: <FiLink2 />,
    link: "/dashboard/dynamic-qr",
  },

  {
    title: "Settings & Account",
    icon: <FiSettings />,
    link: "/dashboard/settings",
  },
];

/*
{
    title: "Payment Gateway",
    icon: <FiCreditCard />,
    link: "/dashboard/gateway",
  },
*/
