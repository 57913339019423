import "./MoreInfo.css";

const MoreInfo = ({ data }) => {
  const { status, info } = data;
  return (
    <div className="MoreInfoContainer">
      <h4>{status}</h4>
      <p>{info}</p>
    </div>
  );
};

export default MoreInfo;
