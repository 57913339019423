import React from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Notfound = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  return (
    <div className="notFound">
      <h1>Page Not Found</h1>
      {user ? (
        <p
          className="underlined"
          onClick={function () {
            navigate(`/dashboard/home`);
          }}
        >
          Back to Home
        </p>
      ) : (
        <p
          className="underlined"
          onClick={function () {
            navigate(`/`);
          }}
        >
          Sign In
        </p>
      )}
    </div>
  );
};

export default Notfound;
