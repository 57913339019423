import { toast } from "react-toastify";

export const createLinkPayload = (reference, branchPrefix, amount, user) => {
  const linkPayload = {
    email: "terminal",
    reference: reference,
    description: branchPrefix,
    amount: +amount,
    wholesaleAccountNumber: "-",
    uid: user.uid,
    sendMethod: "none",
    status: "PENDING",
    merchant: user.displayName,
    counter: 0,
    currency: "GBP",
  };
  return linkPayload;
};

export const showToast = (text, error) => {
  if (error) {
    toast.error(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else {
    toast.success(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { backgroundColor: "#13aa52" },
    });
  }
};
