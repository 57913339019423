import "../styles.css";
import "./settings.css";
import { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

import SettingsBox from "./components/SettingsBox";
import SettingsForm from "./components/SettingsForm";
import SettingsInput from "./components/SettingsInput";

import EmployeeItems from "./components/EmployeeItem";
import {
  CurrencyOptions,
  SendEmailOptions,
} from "./components/CurrencyOptions";

const Settings = ({ employees, merchantDetails, getAllMerchantDetails }) => {
  const { user, logout, changeEmail, changePassword, changeDisplayName } =
    UserAuth();
  const [email, setEmail] = useState(user.email);
  const [displayName, setDisplayName] = useState(user.displayName);
  const [currentPassword, setCurrentPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [emName, setEmName] = useState("");
  const [emInitials, setEmInitials] = useState("");
  const [currency, setCurrency] = useState(merchantDetails.currency || "GBP");
  const [sendEmail, setSendEmail] = useState(merchantDetails.sendEmail);

  const updateUserEmail = async (e) => {
    e.preventDefault();
    if (email === user.email) return;

    if (!currentPassword) return;

    await changeEmail(email, currentPassword);

    setCurrentPassword("");
  };

  const updateDisplayName = async (e) => {
    e.preventDefault();
    if (!displayName) return;

    if (displayName === user.displayName) return;

    await changeDisplayName(displayName);
  };

  const updateUserPassword = async (e) => {
    e.preventDefault();
    if (oldPassword === newPassword) return;
    if (newPassword !== confirmNewPassword) {
      alert("Passwords do not match. Try again.");
      return;
    }

    await changePassword(newPassword, oldPassword);

    setConfirmNewPassword("");
    setOldPassword("");
    setNewPassword("");
  };

  const updateCurrency = async (e) => {
    e.preventDefault();
    if (currency === merchantDetails.currency) return;

    const merchantDoc = doc(db, "merchants", user.uid);
    try {
      await updateDoc(merchantDoc, {
        currency: currency,
      });
      // TOAST
    } catch (error) {
      console.log(error);
    }
  };

  const updateSendEmail = async (e) => {
    e.preventDefault();
    if (sendEmail === merchantDetails.sendEmail) return;

    const merchantDoc = doc(db, "merchants", user.uid);
    try {
      await updateDoc(merchantDoc, {
        sendEmail: sendEmail,
      });
      // TOAST
    } catch (error) {
      console.log(error);
    }
  };

  const newEmployee = async (e) => {
    e.preventDefault();

    await addDoc(collection(db, `merchants/${user.uid}/employees`), {
      name: emName,
      initials: emInitials,
    });
    setEmInitials("");
    setEmName("");
    getAllMerchantDetails();
  };

  const deleteEmployee = async (id) => {
    try {
      await deleteDoc(doc(db, `merchants/${user.uid}/employees`, id));
      await getAllMerchantDetails();
    } catch (error) {
      console.log(error);
    }
  };

  if (!merchantDetails) return;

  return (
    <div className="settings-wrapper">
      <div className="settings-page-heading">
        <h1 className="page-title">Settings</h1>
        <button className="button-37" onClick={logout}>
          Logout
        </button>
      </div>

      <SettingsBox
        title="Account Details"
        subtitle="Update your account details here."
      >
        <SettingsForm title="Update your Email" onSubmit={updateUserEmail}>
          <SettingsInput
            value={email}
            onChange={setEmail}
            type="email"
            placeholder={email}
            label="Change Email Here:"
          />
          <SettingsInput
            value={currentPassword}
            onChange={setCurrentPassword}
            type="password"
            placeholder="Current Password..."
            label="Current Password:"
          />
        </SettingsForm>

        <SettingsForm
          title="Update your Display Name"
          onSubmit={updateDisplayName}
        >
          <SettingsInput
            value={displayName}
            onChange={setDisplayName}
            type="text"
            placeholder="New Display Name..."
            label="Change Display Name Here:"
          />
        </SettingsForm>

        <SettingsForm
          title="Update your Password"
          onSubmit={updateUserPassword}
        >
          <SettingsInput
            value={oldPassword}
            onChange={setOldPassword}
            type="password"
            placeholder="Current Password..."
            label="Enter Current Password:"
          />
          <SettingsInput
            value={newPassword}
            onChange={setNewPassword}
            type="password"
            placeholder="Enter New Password..."
            label="Enter New Password:"
          />
          <SettingsInput
            value={confirmNewPassword}
            onChange={setConfirmNewPassword}
            type="password"
            placeholder="Confirm New Password..."
            label="Confirm New Password:"
          />
        </SettingsForm>
      </SettingsBox>

      <SettingsBox
        title="Employees"
        subtitle="Manage the employees who use your dashboard."
      >
        <SettingsForm title="Create New Employee" onSubmit={newEmployee}>
          <SettingsInput
            value={emName}
            onChange={setEmName}
            type="text"
            placeholder="Employee Name..."
            label="Enter Employee Name:"
          />
          <SettingsInput
            value={emInitials}
            onChange={setEmInitials}
            type="text"
            placeholder="Employee Initials..."
            label="Enter Employee Initials::"
          />
        </SettingsForm>
        <EmployeeItems employees={employees} deleteEmployee={deleteEmployee} />
      </SettingsBox>

      <SettingsBox
        title="Dashboard Settings"
        subtitle="Manage your dashboard settings."
      >
        <SettingsForm title="Dashboard Currency" onSubmit={updateCurrency}>
          <CurrencyOptions
            currency={merchantDetails.currency}
            setCurrency={setCurrency}
          />
        </SettingsForm>

        <SettingsForm
          title="Automatically Send Email"
          onSubmit={updateSendEmail}
        >
          <SendEmailOptions
            sendEmail={merchantDetails.sendEmail}
            setSendEmail={setSendEmail}
          />
        </SettingsForm>
      </SettingsBox>
    </div>
  );
};

export default Settings;
