import React from "react";

const SettingsInput = ({ value, onChange, type, placeholder, label }) => {
  return (
    <>
      <label className="settings-input-label" htmlFor={label}>
        {label}
      </label>
      <input
        className="settings-input-box"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeholder}
        required
        id={label}
      />
    </>
  );
};

export default SettingsInput;
