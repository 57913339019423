export const contentLeft = [
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/cb7e85f33d1b77193e639751852bb99195f4df0d.png",
    alt: "WhatsApp Is Now Live",
    title: "WhatsApp is now live",
    description:
      "You can now send payment requests using WhatsApp to your customers mobile phone. Use the buttons below to see how it works!",
  },
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/3b40d9a5821a5ba9381a0ac02dbd313a4ba7bcfb.png",
    alt: "Setting up a customer",
    title: "Create or Edit customer records",
    description:
      "When setting up the customer, make sure to use a UK mobile number since we add +44 to the start of the number. For example, if the customer's mobile number is 07812345678, you would add it as 7812345678.",
  },
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/ae34bd3c7d9e2d96d1697cad398337a83a5677e5.png",
    alt: "Send a Payment Request by WhatsApp",
    title: "Sending a Payment Request by WhatsApp",
    description:
      "Create a payment by selecting the new payment button as you usually do when sending an email. If a number is set up, you can send the link by WhatsApp. The link is sent from echopays business WhatsApp, so it's recommended that your customers add it to their contact list.",
  },
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/4d4e7ce1adc2e1ff6a0b94fccd0681c621590abb.png",
    alt: "WhatsApp Message Notification",
    title: "WhatsApp Message Notification",
    description:
      "Just like an email, your customers will receive a notification from WhatsApp that includes your name, the amount and the payment link. It will be the same experience for your customers that they're use to.",
  },
];

export const contentRight = [
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/7f7e3de06c29ad5a97f534efa8d60e7e62f03870.png",
    alt: "New Employee List",
    title: "Add different Employees to your dashboard",
    description:
      "Do you have lots of employees who use your dashboard and you want to easily know who has sent what link? We have now added a new feature that allows you to add a list of employees.",
  },
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/e5480a23a3c223e0170cb53aa021535f8da93ffc.png",
    alt: "Setting up an employee",
    title: "Add and Delete Employees",
    description:
      "To start adding new employees to your list, simply head over to the settings page. This feature is completely optional, so if you don't need it, you can leave the list empty.",
  },
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/6db65f51bab61dcf1bbdfd63e1bac08e63055d6b.png",
    alt: "Delete and view your employee",
    title: "View and delete your employees list",
    description:
      "You can easily manage your employees list from the settings page. From there, you can view all of your employees and choose to delete any that are no longer needed. By deleting all of your employees, the selection box will be automatically removed from the new payment request screen",
  },
  {
    url: "https://bucket.mlcdn.com/a/2865/2865955/images/2ca2fd83874220f0dfeb9cf21902b3b881597b5c.png",
    alt: "Select an employee",
    title: "Select your employee name when creating a link",
    description:
      "A selection box will be added to the new payment screen if you have added 1 or more employees to your list. Simply select your name from the options provided, and your initials will be automatically added to the beginning of the payment description. ",
  },
];
