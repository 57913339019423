import React, { useState } from "react";

import { createNewPaymentLink } from "../Customers/actions/createCustomer";
import { createLinkPayload, showToast } from "./utilities";
import { updateKineticCollection, changeShowToFalse } from "./firestore";

const PaymentForm = ({ user, id, branchPrefix, surchargePercentage }) => {
  const [paymentMethod, setPaymentMethod] = useState("EchoPay");
  const [amount, setAmount] = useState(0);
  const [surchargeAmount, setSurchargeAmount] = useState(0);
  const [shouldSurcharge, setShouldSurcharge] = useState(false);
  const [reference, setReference] = useState("");

  const onAmountChange = (e) => {
    setAmount(e.target.value);
    const surchargeDecimal = surchargePercentage / 100;
    const surchargeAmount = +e.target.value * surchargeDecimal;
    const surchargeTotal = surchargeAmount + +e.target.value;
    setSurchargeAmount(surchargeTotal.toFixed(2));
  };

  const resetForm = () => {
    setAmount(0);
    setSurchargeAmount(0);
    setShouldSurcharge(false);
    setReference("");
  };

  async function onSubmit(e) {
    e.preventDefault();
    if (amount < 0.01 || amount == null) {
      alert("Amount is below £0.01.");
      showToast("Please enter an amount.", true);
      return;
    }

    if (paymentMethod === "EchoPay") {
      const linkPayload = createLinkPayload(
        reference,
        branchPrefix,
        amount,
        user
      );
      const [newDoc, newDocError] = await createNewPaymentLink(linkPayload);

      if (newDocError) {
        showToast(newDocError, true);
        return;
      }

      const [update, updateErr] = await updateKineticCollection(
        id,
        true,
        amount,
        false,
        newDoc
      );

      resetForm();
      changeShowToFalse(id);

      if (update) {
        showToast("Notification Sent to terminal.", false);
        return;
      }

      if (updateErr) {
        showToast("Error. Please try again.", true);
        return;
      }
    } else {
      const [update, updateErr] = await updateKineticCollection(
        id,
        false,
        +amount,
        shouldSurcharge,
        null
      );
      resetForm();
      changeShowToFalse(id);
      if (update) {
        showToast("Notification Sent to terminal.", false);
        return;
      }

      if (updateErr) {
        showToast("Error. Please try again.", true);
        return;
      }
    }
  }

  return (
    <form onSubmit={onSubmit} className="terminal-payment-form">
      <div className="terminal-radio-btns">
        <p>Select a Payment Type: </p>
        <input
          type="radio"
          required
          value="EchoPay"
          checked={paymentMethod === "EchoPay"}
          onChange={() => setPaymentMethod("EchoPay")}
        />
        <label>EchoPay</label>
        <input
          type="radio"
          required
          value="Card"
          checked={paymentMethod === "Card"}
          onChange={() => setPaymentMethod("Card")}
        />
        <label>Card</label>
      </div>
      <div className="terminal-form-inputs">
        <label className="terminal-form-input">
          Amount:
          <input
            className="inputStyle100W"
            type="number"
            value={amount}
            required
            step="0.01"
            onChange={onAmountChange}
          />
        </label>
        {paymentMethod === "EchoPay" ? (
          <label className="terminal-form-input">
            Reference:
            <input
              disabled={paymentMethod === "EchoPay" ? false : true}
              className="inputStyle100W"
              required
              type="text"
              value={reference}
              onChange={(event) => setReference(event.target.value)}
            />
          </label>
        ) : (
          <label className="terminal-form-input">
            Surcharge Total Amount:
            <input
              className="inputStyle100W"
              type="number"
              step="0.01"
              value={surchargeAmount}
              disabled
            />
          </label>
        )}
      </div>
      <div className="terminal-form-btn">
        {paymentMethod === "EchoPay" ? (
          <button type="submit" className="button-37">
            Send Notification
          </button>
        ) : (
          <>
            <button
              type="submit"
              className="button-37"
              onClick={() => setShouldSurcharge(false)}
            >
              NO Surcharge
            </button>
            <button
              type="submit"
              className="button-37"
              onClick={() => setShouldSurcharge(true)}
            >
              Surcharge
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default PaymentForm;
