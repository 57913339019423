import { useEffect, useState } from "react";
import { Loader } from "../enduser/components/Loader";
import MoreInfo from "./MoreInfo";
import { cloudFunction } from "./functions";

const PisTokenRequest = () => {
  const [data, setData] = useState({ status: "-", info: "-" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const uid = params.get("uid");
    const amount = params.get("amount");
    const reference = params.get("reference");
    if (!uid || !amount || !reference) {
      setLoading(false);
      return;
    }
    async function callCloudFunction() {
      const [data, error] = await cloudFunction(
        { uid, amount, reference: reference.toUpperCase() },
        "pis-tokenrequest"
      );

      if (error) {
        setData(error);
        setLoading(false);
        return;
      }

      if (data.url) {
        window.location.href = data.url;
      } else {
        setData(data);
        setLoading(false);
        return;
      }
    }
    callCloudFunction();
  }, []);

  return loading ? <Loader /> : <MoreInfo data={data} />;
};

export default PisTokenRequest;
